@import "./styles";

.initial-screen-content-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  z-index: -1;
}
.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black-color-3);
}
.initial-screen-content-wrapper {
  position: relative;
  padding: 16px 16px 47px;
}
.hotel-logo {
  max-width: 120px;
  max-height: 120px;
  overflow: hidden;
  margin: 30px auto 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
  }
}
.hotel-logo-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  .thru-logo {
    max-width: 100px;
    height: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .select-language {
    position: relative;
    display: flex;
    align-items: center;
    .down-arrow-select {
      position: absolute;
      right: 15px;
      top: 9px;
      width: 8px;
      height: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow {
        border-color: var(--black-color-3);
        padding: 3.5px;
      }
    }
  }
}
.header-logo-container {
  width: 100%;
  max-width: 480px;
  margin-bottom: 16px;
  padding: 30px 16px 0;
}
.initial-screen-wrapper {
  width: 100%;
  /* height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  @include align-center-items;*/
}
.initial-screen-hotel-container {
  max-width: 390px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 var(--black-color-2);
  background-color: var(--white-color);
}

select {
  border-radius: 14px;
  border: solid 0.5px var(--black-color-1);
  background-color: var(--white-color);
  padding: 5px 30px 5px 12px;
  font-family: var(--font-Text-Regular);
  color: var(--black-color);
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  &:focus {
    outline: 0;
  }
}
.hotel-information {
  margin-top: 24px;
  .hotel-name {
    font-family: var(--font-Bold);
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.2px;
    color: var(--color-dark-gray);
    span {
      display: block;
    }
  }
  .hotel-address {
    font-family: var(--font-Regular);
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    margin-top: 8px;
    span,
    a {
      display: block;
      color: var(--black-color-7);
      text-decoration: none;
    }
  }
}
.hotel-description {
  font-family: var(--font-Regular);
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  color: var(--black-color);
  margin-top: 24px;
  span {
    display: block;
  }
}
.primary-button {
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 var(--black-color-2);
  background-color: var(--primary-blue);
  font-family: var(--font-Medium);
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--white-color);
  width: 100%;
  position: relative;
  outline: none;
  padding: 15px 20px 13px;
  border: 0;
  max-width: 500px;
}
.secondary-button {
  border-radius: 10px;
  background-color: var(--white-color);
  font-family: var(--font-Medium);
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--color-dark-gray);
  width: 100%;
  position: relative;
  outline: none;
  padding: 15px 20px 13px;
  border: 1px solid var(--black-color-1);
  max-width: 500px;
  margin-bottom: 8px;
  i {
    border-color: var(--color-dark-gray);
  }
}
.hotel-get-started {
  margin-top: 24px;
  @include align-center-items;
}
.initial-screen-content-wrapper {
  &.stop-loading {
    .thru-logo {
      opacity: 1;
      transition-delay: 1.2s;
    }
  }
}
.hotel-privacy-policy-container {
  font-family: var(--font-Text-Regular);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.24px;
  color: var(--black-color-7);
  margin-top: 24px;
  @include align-center-between;
  align-items: flex-end;
  > div a {
    display: inline-block;
    color: var(--primary-blue);
  }
  .thru-logo {
    min-width: 70px;
    width: 70px;
    margin-left: 20px;
    display: inline-flex;
    align-items: center;
    opacity: 0;
    img {
      max-width: 100%;
    }
  }
}

body.model_open {
  overflow: hidden;
  .initial-screen-content-wrapper {
    position: fixed;
    left: 0;
  }
}

.reservation-screen-wrapper {
  //padding: 0 16px 180px;
  padding: 0 16px 100px;
  max-width: 480px;
  margin: 0 auto;
  &.single {
    //padding: 0 16px 120px;
    padding: 0 16px 80px;
  }
  &.multiple {
    //padding: 0 16px 120px;
    padding: 0 16px 142px;
  }
}
.reservation-detail {
  position: relative;
}
.reservation-detail h2 {
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.41px;
  color: var(--header-color);
  margin-top: 16px;
  font-family: var(--font-Bold);
}
.reservation-detail h4 {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.01px;
  color: var(--black-color-3);
  margin-top: 2px;
  font-family: var(--font-Text-Regular);
  text-transform: uppercase;
}

.guest-2column {
  display: flex;
  justify-content: flex-start;
}
.guest-info-detail {
  margin-top: 24px;
  flex: 1;
  .guest-id {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.24px;
    color: var(--black-color-6);
    font-family: var(--font-Text-Regular);
  }
  .guest-value {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-color);
    font-family: var(--font-Medium);
  }
}
.keyboard_up .process-section-details {
  min-height: 200vh;
}

.footer-btn {
  position: fixed;
  // padding: 8px 8px 44px;
  padding: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: var(--white-color-9);
  z-index: 99;
  .primary-button {
    box-shadow: none;
  }
  & + .footer-container {
    .footer-backdrop {
      height: 108px;
    }
  }
}
.footer-2-btns {
  & + .footer-container {
    .footer-backdrop {
      // height: 172px;
      height: 138px;
    }
  }
  .pay_multiple_btn {
    justify-content: space-between;
    width: 100%;
  }
}

.span16 {
  display: block;
  height: 16px;
}
.process-section {
  margin: 16px -16px 0;
}

.ja {
  .process-section-wrapper {
    .process-section-container {
      .process-section-content {
        span {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.process-section-wrapper {
  .process-section-container {
    background: var(--light-gray-color-04);
    padding: 16px;
    position: relative;
    .process-section-content {
      span {
        display: inline-block;
        font-size: 14px;
        border-radius: 14px;
        padding: 5px 12px;
        border: solid 0.5px var(--black-color-1);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        background: var(--white-color);
        letter-spacing: -0.24px;
        color: var(--black-color-3);
        margin-right: 24px;
        position: relative;
        &.active {
          background: var(--header-color);
          color: var(--white-color);
          i.payment {
            border-color: var(--light-gray-color-08);
          }
        }
        &.complete {
          background: var(--light-gray-color-08);
          border: solid 0.5px transparent;
          i {
            border-color: var(--light-gray-color-08);
          }
        }
        i {
          position: absolute;
          right: -15px;
          top: 10px;
          border-color: var(--black-color-3);
          &.payment {
            left: -18px;
            right: auto;
          }
        }
      }
    }
  }
}
.process-section-detail-header h3 {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black-color);
  margin-top: 36px;
}

.process-section-detail-subheader h6 {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--header-color);
  margin-top: 36px;
  font-family: var(--font-Bold);
}

.wallet_wrapper {
  padding: 24px;
  background: var(--white-color);
}
.wallet_button_details {
  padding: 0 24px 24px;
  text-align: center;
}

.scan-id-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 340px;
  justify-content: center;
  align-items: center;
  background: var(--light-gray-color-04);
  margin-top: 30px;
  color: var(--black-color-3);
  font-family: var(--font-Text-Semibold);
  &.active {
    color: var(--primary-blue);
  }
  .error_container {
    position: absolute;
    top: 20px;
    color: var(--error-color);
  }
  .scan-id-content {
    @include align-center-items;
    flex-direction: column;
    text-transform: uppercase;
    img {
      margin-bottom: 16px;
      max-width: 100%;
      border-radius: 10px;
      max-height: 190px;
    }
    .scan-button {
      position: absolute;
      top: 10px;
      right: 8px;
      color: var(--black-color-3);
      font-size: 14px;
      line-height: 18px;
      border-radius: 14px;
      background-color: var(--white-color);
      border: 0.5px solid var(--light-gray-color-04);
      padding: 5px 12px;
    }
  }
}
.process-section-wrapper.sticky {
  .process-section-container {
    background: transparent;
  }
  .backdrop-shadow {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
}
.sticky-process.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--white-color-9);
  box-shadow: 0 0 20px 0 var(--black-color-2);
}
.primary-button.option-display,
.secondary-button.option-display {
  font-size: 17px;
  padding: 17px 20px 17px;
  @include align-center-between;
  span {
    display: flex;
    align-items: center;
    i {
      margin-left: 5px;
    }
  }
}
.primary-button.option-display.disabled {
  background: var(--black-color-3);
}

.guest-2column {
  .select-wrapper,
  .input-wrapper,
  .datepicker-wrapper {
    &:first-child {
      margin-right: 16px;
    }
  }
}
.MuiDialog-root {
  .MuiPickersToolbar-toolbar,
  .MuiPickersDay-daySelected,
  .MuiPickersDay-daySelected:hover {
    background-color: var(--primary-blue);
  }
  .MuiButton-textPrimary {
    color: var(--primary-blue);
  }
}

.MuiAutocomplete-root {
  .MuiAutocomplete-popupIndicator {
    margin-right: 0;
    display: none;
  }
}

.input-wrapper {
  .MuiInputLabel-formControl {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.select-wrapper,
.input-wrapper,
.datepicker-wrapper {
  width: 100%;
  margin-top: 24px;
  position: relative;
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1);
    transform-origin: top left;
    color: var(--black-color-6);
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormControl-root {
    margin: 0;
  }
  .MuiSelect-icon {
    display: none;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0.5px solid var(--black-color-1);
  }
  .MuiInput-underline:after {
    border-bottom: 0.5px solid var(--primary-blue);
  }
  .MuiInputBase-input {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-color);
    font-family: var(--font-Medium);
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-color);
    font-family: var(--font-Medium);
  }
  .MuiInputLabel-formControl {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.24px;
    color: var(--black-color-3);
    font-family: var(--font-Medium);
    &.MuiInputLabel-shrink {
      color: var(--black-color-6);
      font-size: 14px;
      font-family: var(--font-Text-Regular);
    }
  }
}

.ja {
  .select-wrapper,
  .input-wrapper,
  .datepicker-wrapper {
    .MuiInputLabel-formControl {
      font-size: 14px;
    }
  }
}

.error-text {
  color: var(--error-color);
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
  font-family: var(--font-Text-Regular);
}

.splash-screen-full {
  background: #fff;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 1.5s ease;
  z-index: 99;
  border-radius: 0;
  &.stop-loading {
    width: 70px;
    height: 40px;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    margin-left: -35px;
    opacity: 0;
    margin-top: -20px;
    img {
      opacity: 0;
      /*max-width: 50%;
      margin-left: -75px;
      margin-top: 0;*/
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div > img {
    width: 150px;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    transition: opacity 1.5s ease-in-out;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -75px;
    margin-top: -75px;
    z-index: 999;
    opacity: 1;
  }
}

.initial-screen-content-wrapper {
  transition: 1.5s;
  transform: translateY(100%);
  &.stop-loading {
    transition: 1.5s;
    transform: translateY(0);
  }
}

.magic-text-wrapper {
  background: var(--light-gray-color-08);
  border: 0.5px solid var(--light-gray-color-04);
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
  .magic-text-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      display: block;
    }
    .magic-image {
      margin-right: 16px;
    }
  }
}

.magic-text {
  font-family: var(--font-Text-Regular);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.24px;
  color: var(--black-color-6);
}
.separator_sign {
  display: block;
  height: 0.5px;
  background-color: var(--black-color-6);
  width: 100%;
  margin-top: 48px;
}
.rules-regulations {
  font-family: var(--font-Text-Regular);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.24px;
  color: var(--black-color-6);
  margin-top: 54px;
  ul {
    padding-left: 16px;
    list-style-type: disc;
  }
  ol {
    padding-left: 16px;
    list-style-type: lower-alpha;
  }
  &.payment {
    margin-top: 15px;
    .select_payment {
      color: var(--black-color-6);
      font-family: var(--font-Text-Regular);
      font-size: 14px;
      margin-bottom: 0;
    }
    .radio_container {
      margin-top: 10px;
      .radio_image,
      .radio_label {
        display: flex;
        align-items: center;
      }
    }
  }
  &.health_declaration {
    margin-top: 48px;
    .declaration_options {
      span {
        display: inline-block;
        margin-left: 5px;
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }

  h2 {
    font-family: var(--font-Bold);
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    margin-bottom: 24px;
  }
  ul {
    list-style-type: disc;
    padding-left: 16px;
    li {
      margin-bottom: 16px;
    }
  }
}

.sign-wrapper {
  position: relative;
}

.sign-here {
  position: absolute;
  color: var(--primary-blue);
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--font-Text-Semibold);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  left: 0;
  margin-left: 0;
  top: 70px;
  width: 100%;
}
.none-display {
  display: none;
}

.clear-btn {
  font-family: var(--font-Text-Regular);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.24px;
  color: var(--black-color-3);
  border-radius: 14px;
  border: solid 0.5px var(--black-color-3);
  background-color: var(--white-color);
  position: absolute;
  right: 8px;
  top: 8px;
  box-shadow: none;
  width: 58px;
  height: 28px;
  padding: 0;
  &:focus {
    outline: none;
  }
}

.sigContainer {
  width: 100%;
  margin: 16px auto;
  background-color: var(--white-color);
  border: 2px solid var(--primary-blue);
  border-radius: 4px;
}

.sigPad {
  width: 100%;
  height: 140px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.background-desktop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  justify-content: center;
  display: none;
}

.payment-text-wrapper {
  font-family: var(--font-Text-Regular);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.24px;
  color: var(--black-color-6);
  margin-top: 24px;
  > div {
    margin-bottom: 16px;
  }
}

html,
#root,
.main,
.main-thru-container {
  height: 100%;
}
.keyboard_up {
  .footer-btn {
    display: none;
  }
}

.booking_page {
  background: var(--booking-background);
  padding: 0 16px 50px;
  overflow-y: auto;
  height: 100%;
}

.wallet-complete-text {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black-color);
  font-family: var(--font-Medium);
  text-align: center;
  .confirm_bold {
    font-family: var(--font-Bold);
  }
  .primary_color {
    color: var(--primary-blue);
    font-family: var(--font-Bold);
  }
  .para_sanitize {
    margin-top: 17px;
  }
}
.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  margin: 24px auto 0;
  img {
    max-width: 100%;
  }
}
.guest-info-detail.arrival-info {
  text-align: center;
}
.wallet-buttons-wrapper {
  // display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 0;
  .wallet-buttons {
    border-radius: 7.3px;
    background-color: var(--black-color);
    font-size: 14.7px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    box-shadow: none;
    border: 0;
    padding: 0;
    outline: none;
    height: 44px;
    width: 100%;
    color: var(--white-color);
    &.apple {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      margin-right: 15px;
      .wallet-text {
        span {
          display: block;
        }
        .addto {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  .wallet {
    width: 35px;
    height: 25px;
    overflow: hidden;
    margin: 0 5px 0 10px;
  }
  .gpay {
    width: 10px;
    height: 10px;
    display: inline-block;
  }
  img {
    max-width: 100%;
    margin-bottom: 24px;
  }
}

.save-link {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-blue);
  font-family: var(--font-Medium);
  width: 100%;
  display: block;
  text-align: center;
  &.pdf {
    margin: 16px 0 0;
  }
}

.thru-logo-wallet {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  img {
    max-width: 100%;
  }
}
span.optional {
  font-family: var(--font-Text-Regular);
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.24px;
  color: var(--black-color-3);
}

.help_text_id {
  font-size: 13px;
  margin-top: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black-color-7);
  font-family: var(--font-Light);
  b {
    font-family: var(--font-Bold);
  }
}
.expire_page {
  .error-component-wrapper {
    .error-component-modal {
      .error-heading {
        font-size: 25px;
      }
    }
    .error-description {
      a {
        color: var(--primary-blue);
        cursor: pointer;
        &:hover {
          color: var(--black-color-3);
        }
      }
    }
  }  
}
.error-component-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(236, 241, 245, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 6px;
  .errorClose span {
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }
  .error-component-modal {
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 12px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 20px 0 var(--black-color-2);
    background-color: var(--white-color-9);
    width: 100%;
    max-width: 480px;
    padding: 75px 16px 105px;
    &.privacy_modal {
      padding: 55px 16px 25px;
      max-height: 90vh;
      font-size: 13px;
      top: -30px;
      .error-heading {
        font-size: 25px;
        color: var(--black-color);
      }
      .error-description {
        max-height: 55vh;
        overflow-y: auto;
        color: var(--black-color);

        h1 {
          font-size: 18px;
          font-family: var(--font-Bold);
          font-weight: bold;
          margin: 20px 0;
        }
        h2 {
          font-size: 16px;
          font-family: var(--font-Medium);
          font-weight: bold;
          margin: 10px 0;
        }
        h3 {
          font-family: var(--font-Medium);
          font-size: 14px;
          font-weight: bold;
          margin: 10px 0;
        }
        ul {
          // list-style-type: disc;
          // padding-left: 20px;
          font-size: 13px;
          line-height: 20px;
        }
        .privacy_policy {
          font-size: 13px;
          line-height: 20px;
          font-family: var(--font-Text-Regular);
        }
      }
    }
    .errorClose span {
      display: inline-block;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
    .error-heading {
      font-family: var(--font-Bold);
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: 0.41px;
      color: var(--color-dark-gray);
    }
    .error-description {
      font-family: var(--font-Light);
      font-size: 21px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: var(--black-color-7);
      margin-top: 8px;
    }
  }
}

.pay-details-wrapper {
  border-bottom: 1px solid var(--black-color-1);
  padding-bottom: 8px;
  position: relative;
  .check-image-pay {
    position: absolute;
    right: 0;
    bottom: 4px;
  }
}

.web-cam-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black-color);
  z-index: 999;
  video {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  div.outer-circle {
    background: var(--white-color);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -25px;
    box-shadow: 0 0 10px var(--white-color-9);
  }
  span.inner-circle {
    background: var(--black-color);
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span.inner {
    background: var(--white-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
  }
}
.radio_container {
  display: flex;
  margin-top: 10px;
  min-height: 26px;
  .container_radio {
    margin-right: 20px;
  }

  .radio_image {
    margin-right: 16px;
  }
  .radio_label {
    display: flex;
    align-items: center;
    font-family: var(--font-Regular);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.24px;
    color: var(--black-color);
  }
}

.health_question_wrapper {
  padding-bottom: 15px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  &:last-child {
    border-bottom: 0;
  }
}

.label-phone {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.24px;
  font-family: var(--font-Medium);
  color: var(--black-color-6);
}
/* The container */
.container_check,
.container_radio {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  // font-family: "inter_medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 28px;
  margin-bottom: 0;
  letter-spacing: normal;
  color: var(--color-black);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.disabled {
    color: #5e636e;
  }
}
/* Hide the browser's default checkbox */
.container_check input,
.container_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark_check {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-image: url("../images/UnCheck.svg");
  background-position: 0;
}
.container_radio {
  .checkmark_check {
    background-image: url("../images/radioUncheck.svg");
    background-position: 0;
    &:after {
      background-image: url("../images/radio.svg");
      background-position: 0;
    }
  }
  input:checked ~ .checkmark_check{
    background-image: url("../images/radio.svg");
    background-position: 0;
  }
}
/* Create the checkmark_check/indicator (hidden when not checked) */
.checkmark_check:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-image: url("../images/Check2.svg");
  background-position: 0;
  display: none;
}
/* Show the checkmark_check when checked */
.container_check input:checked ~ .checkmark_check:after,
.container_radio input:checked ~ .checkmark_check:after {
  display: block;
}
/* Show the checkmark_check when checked */
.container_check input:disabled ~ .checkmark_check:after {
  display: block;
  background-image: url("../images/Check3.svg");
}

@media only screen and (min-width: 500px) {
  .header-container-outer {
    display: none;
  }
  .background-desktop {
    display: flex;
  }
  // body {
  //   overflow: hidden;
  // }
  .reservation-screen-wrapper {
    max-width: 420px;
    background: var(--white-color);
    position: relative;
    padding: 0 16px 80px;
  }
  .footer-btn {
    position: absolute;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .reservation-detail::-webkit-scrollbar {
    display: none;
  }
  .reservation-screen-wrapper {
    .reservation-detail {
      height: calc(80vh - 80px);
      overflow-y: auto;
      scroll-behavior: smooth;
    }
    &.single {
      .reservation-detail {
        height: calc(80vh - 80px);
        overflow-y: auto;
      }
    }
    &.multiple {
      .reservation-detail {
        height: calc(80vh - 142px);
        overflow-y: auto;
      }
    }
  }

  .main-thru-container.outer {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
    background: var(--white-color);
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
    top: 100px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  .main {
    display: flex;
    justify-content: center;
    height: 100vh;
  }
  .header-logo-container {
    display: none;
  }
  .process-section {
    margin: 16px 0 0;
  }
  .process-section-wrapper.sticky {
    top: 50px;
    left: 50%;
    z-index: 9;
    width: 420px !important;
    margin-left: -210px;
  }
  .process-section-wrapper.sticky .process-section-content {
    padding-left: 16px;
  }
}