@import "./styles";

.header-logo-container {
  @include align-center-between;
  .hotel-logo-inner {
    max-width: 100px;
    max-height: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .thru-logo {
    max-width: 60px;
    position: relative;
  }
  img {
    max-width: 100%;
  }
}

.footer-backdrop {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 32px;
  box-shadow: 0 0 20px 0 var(--black-color-2);
  width: 100%;
}
.footer-backdrop-default {
  @extend .footer-backdrop;
  // height: 108px;
  height: 72px;
}
.footer-2-btns {
  .footer-backdrop-default {
  //  height: 172px;
  height: 138px;
  }
}
.footer-content {
  @include align-center-items;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: 1px;
  color: var(--black-color-6);
  font-family: var(--font-Text-Semibold);
  text-transform: uppercase;
}
.footer-container {
  @include align-center-items;
  width: 100%;
  padding: 7px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  left: 0;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: var(--white-color-9);
}

.static-background {
  position: fixed;
  top:0;
  bottom: 0;
  right: 0;
  max-width: 80px;
  img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 500px) {
  .header-logo-container {
    padding: 30px 0 0;
  }
  .footer-backdrop, .footer-backdrop-default {
    position: absolute;
  }
}
