/*@font-face{font-family:'SFProDisplay-Black';src:url(./../fonts/SFProDisplay/SFProDisplay-Black.otf) format("otf"),
url(./../fonts/SFProDisplay/SFProDisplay-Black.otf) format("otf");font-weight:400;font-style:normal}*/

@import "library";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: SFProDisplay-Black;
  src: url(../fonts/SFProDisplay/SFProDisplay-Black.otf);
}
@font-face {
  font-family: SFProDisplay-Bold;
  src: url(../fonts/SFProDisplay/SFProDisplay-Bold.otf);
}
@font-face {
  font-family: SFProDisplay-Light;
  src: url(../fonts/SFProDisplay/SFProDisplay-Light.otf);
}
@font-face {
  font-family: SFProDisplay-Regular;
  src: url(../fonts/SFProDisplay/SFProDisplay-Regular.otf);
}
@font-face {
  font-family: SFProDisplay-Medium;
  src: url(../fonts/SFProDisplay/SFProDisplay-Medium.otf);
}
@font-face {
  font-family: SFProDisplay-Semibold;
  src: url(../fonts/SFProDisplay/SFProDisplay-Semibold.otf);
}
@font-face {
  font-family: SFProText-Bold;
  src: url(../fonts/SFProText/sf-pro-text-bold.ttf);
}
@font-face {
  font-family: SFProText-Light;
  src: url(../fonts/SFProText/SF-Pro-Text-Light.ttf);
}
@font-face {
  font-family: SFProText-Regular;
  src: url(../fonts/SFProText/SF-Pro-Text-Regular.otf);
}
@font-face {
  font-family: SFProText-Semibold;
  src: url(../fonts/SFProText/SFProText-Semibold.otf);
}
@font-face {
  font-family: Georgia-Regular;
  src: url(../fonts/Georgia/Georgia-Regular.ttf);
}
@font-face {
  font-family: Georgia-Bold;
  src: url(../fonts/Georgia/Georgia-Bold.ttf);
}

body {
  font-family: var(--font-Regular), Helvetica,sans-serif;
}

/*
#root {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
#root::-webkit-scrollbar{
  display: none;
}
*/

.view-order-check {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 81px;
  box-shadow: 0 0 20px 0 var(--black-color-2);
  width: 100%;
}
.confirm-order-check {
  @extend .view-order-check;
  height: 101px;
}

.product-detail-action-button-check {
  @extend  .view-order-check;
  height: 80px;
}

.sticky-filter-check.sticky {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 51px;
  box-shadow: 0 0 20px 0 var(--black-color-2);
  z-index: 99;
}
.product-detail-header-sticky.sticky {
  @extend .sticky-filter-check.sticky;
  height: 51px;
}

@mixin top-shadow{
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
 //box-shadow: 10px 10px 7px var(--black-color-1);
  //box-shadow: 0 0 20px 0 var(--black-color-2);
  background-color: var(--white-color-9);
}

@mixin top-shadow2{
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  // box-shadow: 10px 10px 7px var(--black-color-1);
 // box-shadow: 0 0 0 0 var(--black-color-1);
 // -webkit-box-shadow: 0 0 20px 0 var(--black-color-1);
  background-color: var(--white-color-9);
}

@mixin bottom-shadow{
  box-shadow: 0 0 20px 0 var(--black-color-2);
  background-color: var(--white-color-92);
}

.animate-bottom .filter-container {
  -webkit-backdrop-filter: blur(27.2px);
  backdrop-filter: blur(27.2px);
  padding: 30px 16px 0;
}

h1.error-text {
  color: var(--error-color);
}

@mixin align-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin align-center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin align-start-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

html, body {
  width: 100%;
  height: 100%;
}
body {
  background: var(--background-color);
}
#__next-prerender-indicator {
  display: none;
}

.arrow {
  border: solid var(--white-color);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
a {
  text-decoration: none;
}

input,
textarea,
button,
select,
label,
span,
div,
img,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.landscape-orientation {
  display: none !important;
}

@media screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {

  .android_device {
    .main-thru-container {
      display: block !important;
    }
    .landscape-orientation {
      display: none !important;
    }
    .web-cam-wrapper div.outer-circle {
      right: 20px;
      left: auto;
      top: 50%;
      margin-top: -25px;
      margin-left: 0;
      bottom: auto;
    }
  }
  .main-thru-container {
    display: none !important;
  }
  .landscape-orientation {
    display: flex !important;
    background-color: var(--white-color);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left:0;
    right:0;
    bottom:0;
    top:0;
    z-index: 999;
    justify-content: center;
    align-items: center;
    padding: 25px;
    line-height: 25px;
    font-size: 20px;
  }
}