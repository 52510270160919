html {
  --black-color: #000;
  --white-color: #fff ;
  --gray-color: #cfd7db ;
  --light-gray-color: rgb(118, 118, 128) ;
  --color-dark-gray: #5e636e;
  --background-color: #fff;
  --primary-color: #bba353 ;
  --primary-blue: #1772ad;
  --brown-color: #87732e ;
  --error-color: #ea4335;
  --header-color: #55575c;
  --booking-background: #dde3e6;
  --allergies-wrapper: rgba(207, 215, 219, 0.8);
  --error-wrapper: rgba(207, 215, 219, 0.7);
  --black-color-1: rgba(0,0,0,0.1);
  --black-color-2: rgba(0,0,0,0.2);
  --black-color-3: rgba(0,0,0,0.3);
  --black-color-5: rgba(0,0,0,0.5);
  --black-color-6: rgba(0,0,0,0.6);
  --black-color-7: rgba(0,0,0,0.7);
  --black-color-9: rgba(0,0,0,0.9);
  --black-color-02: rgba(0,0,0,0.02);
  --black-color-05: rgba(0,0,0,0.05);
  --white-color-1: rgba(255,255,255,0.1);
  --white-color-5: rgba(255,255,255,0.5);
  --white-color-7: rgba(255,255,255,0.7);
  --white-color-9: rgba(255,255,255,0.9);
  --white-color-92: rgba(255,255,255,0.92);
  --white-color-99: rgba(255,255,255,0.975);
  --light-gray-color-08: rgba(118, 118, 128, 0.08) ;
  --light-gray-color-04: rgba(118, 118, 128, 0.04) ;

  --font-Regular: SFProDisplay-Regular;
  --font-Medium: SFProDisplay-Medium;
  --font-Bold: SFProDisplay-Bold;
  --font-Light: SFProDisplay-Light;
  --font-Text-Regular: SFProText-Regular;
  --font-Text-Semibold: SFProText-Semibold;
}

html[data-theme="raffles"] {
  --primary-blue: #76a5be;
  --background-color: #f8f8f8;
  --font-Regular: Georgia-Regular;
  --font-Bold: Georgia-Regular;
  --font-Text-Regular: Georgia-Regular;
  --font-Text-Semibold: Georgia-Regular;
}

html[data-theme="custom"] {
  --primary-color: rgb(63,158,63);
  --brown-color: rgb(12,78,12);
}